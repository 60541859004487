<template>
	<div id="noteFound">
		<img :src="require('@/assets/imgs/Notioly1.png')" alt="">
		<div class="noteFound">
			<b>404</b>
			<p>很抱歉</p>
			<p>页面找不到了</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'noteFound',
		components: {},
	}
</script>

<style lang="scss" scoped>
	#noteFound {
		width: 1200px;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 100px auto 0;
		justify-content: center;
		height: 90vh;

		img {
			height: 360px;
		}

		.noteFound {
			display: flex;
			margin-left: 90px;
			flex-direction: column;

			b {
				line-height: 1;
				font-size: 90px;
				color: rgb(203, 50, 44);
			}

			p {
				line-height: 1;
				font-size: 40px;
				margin-top: 20px;
			}
		}
	}
</style>